import './App.css';
import VideoRecorder from './VideoRecorder';

function App() {
  return (
    <div style={{width: '50rem', height: '50rem'}}>
      <VideoRecorder/>
    </div>
  );
}

export default App;
