import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import Mesaj from "./Mesaj";
import { Message } from "primereact/message";

const VideoKaydi = (props) => {
    const videoRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [stream, setStream] = useState(null);
    const [recordedBlob, setRecordedBlob] = useState(null);
    const [videoKaydetVisible, setVideoKaydetVisible] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [erisimIzniVerildiMi, setErisimIzniVerildiMi] = useState(false);
    const [userEnlem, setUserEnlem] = useState(null);
    const [userBoylam, setUserBoylam] = useState(null);
    const [video, setVideo] = useState(null);
    const [izinler, setIzinler] = useState({ camera: "unknown", microphone: "unknown", location: "unknown" });

    useEffect(() => {
        checkLocationPermission();
    }, []);

    const checkLocationPermission = async () => {
        try {
            const locationPermission = await navigator.permissions.query({ name: "geolocation" });
            setIzinler((prev) => ({ ...prev, location: locationPermission.state }));

            if (locationPermission.state === "granted") {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setUserEnlem(latitude);
                        setUserBoylam(longitude);
                        checkCameraAndMicrophonePermissions();
                    },
                    (error) => {
                        console.error("Konum alınamadı:", error);
                    }
                );
            } else {
                requestLocationPermission();
            }
        } catch (error) {
            console.error("İzinler kontrol edilemedi:", error);
        }
    };

    const requestLocationPermission = async () => {
        navigator.geolocation.getCurrentPosition(
            () => checkCameraAndMicrophonePermissions(),
            (error) => {
                console.error("Konum izni verilmedi:", error);
            }
        );
    };

    const checkCameraAndMicrophonePermissions = async () => {
        try {
            const cameraPermission = await navigator.permissions.query({ name: "camera" });
            const microphonePermission = await navigator.permissions.query({ name: "microphone" });

            setIzinler((prev) => ({
                ...prev,
                camera: cameraPermission.state,
                microphone: microphonePermission.state,
            }));

            if (cameraPermission.state === "granted" && microphonePermission.state === "granted") {
                setErisimIzniVerildiMi(true);
                startCamera();
            } else {
                requestCameraAndMicrophonePermissions();
            }
        } catch (error) {
            console.error("İzinler kontrol edilemedi:", error);
        }
    };

    const requestCameraAndMicrophonePermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setErisimIzniVerildiMi(true);
            await startCamera();
        } catch (error) {
            console.error("Kamera veya mikrofon izni verilmedi:", error);
        }
    };

    const startCamera = async () => {
        try {
            

            const stream = await navigator.mediaDevices.getUserMedia({ video: true });

            if (videoRef.current.srcObject) {
              const currentStream = videoRef.current.srcObject;
              currentStream.getTracks().forEach((track) => track.stop());
            }

            videoRef.current.srcObject = stream;

            try {
              await videoRef.current.play();
            } catch (playError) {
              console.error("Error trying to play video:", playError);
            }
        } catch (err) {
            console.error("Kameraya erişilemiyor: ", err);
        }
        
    };

    const kameraKaydiBaslat = () => {
        if (stream) {
            setLoading(true);
            setVideoKaydetVisible(false);
            const mediaRecorder = new MediaRecorder(stream, { mimeType: "video/webm" });
            mediaRecorderRef.current = mediaRecorder;

            const chunks = [];
            mediaRecorder.ondataavailable = (event) => chunks.push(event.data);

            mediaRecorder.onstop = async () => {
                const blob = new Blob(chunks, { type: "video/webm" });
                setRecordedBlob(blob);
                setVideoKaydetVisible(true);
                const arrayBuffer = await blob.arrayBuffer();
                const byteArray = new Uint8Array(arrayBuffer);
                setVideo(byteArray);
                setLoading(false);
            };

            mediaRecorder.start();
            setTimeout(() => mediaRecorder.stop(), props.secilenForm === 1 ? 240000 : 120000);
            stream.getAudioTracks().forEach((track) => (track.enabled = true));
        }
    };

    const kameraKaydiDurdur = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
            mediaRecorderRef.current.stop();
        }
        checkLocationPermission();
    };

    const videoyuKaydet = () => {
        const requestBody = {
            TalepUn: "123456",
            FormTip: "11",
            MedyaTip: 0,
            Enlem: userEnlem,
            Boylam: userBoylam,
            DoimKullaniciUn: "1533",
            MedyaData: Array.from(video),
        };
        console.log(requestBody);
    };

    const izinMesajlari = [
        { izin: izinler.camera, mesaj: "Kamera erişim izniniz onaylanmamış. Kamerayı kullanabilmemiz için lütfen izin veriniz." },
        { izin: izinler.microphone, mesaj: "Mikrofon erişim izniniz onaylanmamış. Mikrofonu kullanabilmemiz için lütfen izin veriniz." },
        { izin: izinler.location, mesaj: "Konum erişim izniniz onaylanmamış. Konum bilginizi kullanabilmemiz için lütfen izin veriniz." },
    ];

    const content = (
        <div className="align-items-center">
            {izinMesajlari.map(
                (item, index) =>
                    item.izin === "denied" && (
                        <div key={index} className="ml-2 flex align-items-center mb-2">
                            <i className="pi pi-exclamation-triangle mr-2"></i>
                            <div style={{ fontSize: "1.25em", fontWeight: "bold" }}>{item.mesaj}</div>
                        </div>
                    )
            )}
        </div>
    );

    const mesaj = () => <Message className="w-full justify-content-start p-3 border-round-lg" severity="warn" content={content} />;

    return (
        <div>
            {!erisimIzniVerildiMi && (
                <div>
                    <Mesaj type="info" content={mesaj} />
                </div>
            )}
            {erisimIzniVerildiMi && (
                <div>
                    {!videoKaydetVisible && <video ref={videoRef} autoPlay style={{ width: "100%" }} />}

                    {videoKaydetVisible && (
                        <>
                            <video controls src={URL.createObjectURL(recordedBlob)} style={{ width: "100%" }} />
                            <br />
                            <Button onClick={videoyuKaydet} label="Gönder" style={{ width: "100%", height: "60px", marginTop: "10px" }} />
                        </>
                    )}
                    <div>
                        <Button label={videoKaydetVisible ? "Tekrar Kaydet" : "Kaydet"} loading={loading} style={{ width: "100%", height: "60px", marginTop: "10px" }} onClick={kameraKaydiBaslat} />
                        <br />
                        {loading && (
                            <Button label={"Kaydı Durdur"} style={{ width: "100%", height: "60px", marginTop: "10px" }} onClick={kameraKaydiDurdur} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoKaydi;
