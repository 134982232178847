import React from "react";

const Mesaj = (props) => {
    /* Bu bileşenin oluşturulmasının nedeni, PrimeReact'ın mevcut mesaj bileşeninin sol tarafında gelen simgenin kaldırılması için bir özelliği olmaması */
    /* "u" uluğ enerjinin u'su :)*/
    /*
        type propunun alacağı değerler
        • primary
        • secondary
        • success
        • info
        • warn
        • help
        • danger
    */

    const className = "u-message u-" + props.type;

    return ( 
        <div className={className}>
            <p className="u-message-text">{props.content()}</p>
        </div>
    )
}

export default Mesaj